/* eslint-disable react/no-danger */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as sH from 'sanitize-html'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import Moment from 'react-moment'

const NewsItem = ({
  slider,
  item: { title, uri, id, date, featuredImage, locations: { nodes: locations } = {} } = {},
}) => {
  const imD = featuredImage ? getImage(featuredImage.node.localFile.childImageSharp.gatsbyImageData) : ''

  const Wrapper = styled.div`
    ${tw`py-5 h-full`}
    ${slider === 'slider' ? tw`md:px-0 h-full` : tw`md:px-3`};
  `
  const Title = styled.h3`
    ${tw`font-display text-ricoDarkGray mb-1 font-normal text-2xl md:text-2xl lg:text-[2.1rem] lg:leading-[1.1]`}
  `
  const Location = styled.small`
    ${tw`text-primary text-sm md:text-base uppercase pb-4 block`}
  `
  const Content = styled.div`
    ${slider === 'slider' ? tw`min-h-[125px] md:min-h-[185px] lg:min-h-[195px] xl:min-h-[185px]` : tw``}
  `
  const Container = styled.div`
    ${tw`px-6 md:px-8 py-8 bg-white flex flex-col justify-between before:content-[''] before:bg-primary relative before:absolute before:top-[-3px] before:text-5xl before:z-30 before:w-24 before:h-[6px]`}
  `

  return (
    <Wrapper key={id}>
      <Link to={uri} className="h-full flex flex-col">
        {imD && <GatsbyImage image={imD} alt={title} />}
        <Container className="grow">
          <Content>
            {locations?.length > 0 && <Location dangerouslySetInnerHTML={{ __html: sH(locations[0].name) }} />}
            <Title dangerouslySetInnerHTML={{ __html: sH(title) }} />
          </Content>
          <Moment format="YYYY-MM-DD" className="text-gray-400 pt-3" date={date} />
        </Container>
      </Link>
    </Wrapper>
  )
}

NewsItem.propTypes = {
  item: PropTypes.object.isRequired,
  slider: PropTypes.string,
}

export default NewsItem
