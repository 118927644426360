/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import * as sH from 'sanitize-html'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Transition } from '@headlessui/react'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon'
import { faChevronLeft as arrowLeft, faChevronRight as arrowRight } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import NewsItem from './News/NewsItem'
import NavButton from '../../utils/NavButton'

import 'swiper/css'
import 'swiper/css/navigation'

const Newslisting = ({ items, scope, slider }) => {
  const [allItems] = useState(items)
  const maxPosts = 6
  // State for the list
  const [list, setList] = useState([...allItems.slice(0, maxPosts)])
  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false)
  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allItems.length > maxPosts)
  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    setList([...allItems.slice(0, maxPosts)])
    }, [allItems]) //eslint-disable-line

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allItems.length
      const nextResults = isMore ? allItems.slice(currentLength, currentLength + maxPosts) : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
      }, [loadMore, hasMore]) //eslint-disable-line

  // Check if there is more
  useEffect(() => {
    const isMore = list.length < allItems.length
    setHasMore(isMore)
    }, [list]) //eslint-disable-line

  const { t } = useTranslation()

  const LoadMore = styled.button`
    ${tw`mx-auto inline-block text-white !bg-primary transition-all font-bold px-10 py-3`}
  `
  const Showing = styled.small`
    ${tw`text-ricoBlack opacity-50 py-3`}
  `
  const Footer = styled.footer`
    ${tw`w-full flex flex-col place-items-center`}
  `
  const ButtonGroup = styled.div`
    ${tw`hidden md:flex relative cursor-pointer mr-2`}
  `
  const Icon = styled(props => <FontAwesomeSvgIcon {...props} />)`
    ${tw`text-sm`}
  `
  const NewsHolder = styled.div`
    ${tw`w-full md:grid md:grid-cols-2 lg:grid-cols-3 auto-rows-max transition-all duration-300 px-8 md:px-0`}
  `
  const newsHolderClasses = 'w-full md:grid md:grid-cols-2 lg:grid-cols-3 auto-rows-max transition-all duration-300'

  const aniOpen = 'opacity-100'
  const aniClose = 'opacity-0'
  const aniClass = 'transition-opacity ease-linear duration-300 h-full'

  const animationProps = {
    className: aniClass,
    enterFrom: aniClose,
    enterTo: aniOpen,
    entered: aniOpen,
  }

  const breakpoints = {
    0: { slidesPerView: 1.2, centeredSlides: true, slidesPerGroup: 1 },
    768: { slidesPerView: 2, slidesPerGroup: 2 },
    1024: { slidesPerView: 3, slidesPerGroup: 3 },
  }

  SwiperCore.use([Navigation])

  const swiperRef = React.useRef(null)

  const [leftDisabled, disableLeft] = useState(true)
  const [rightDisabled, disableRight] = useState(false)

  return (
    <>
      {slider === 'slider' && (
        <ButtonGroup>
          <NavButton direction="Previous" off={leftDisabled} swiperObj={swiperRef}>
            <Icon icon={arrowLeft} />
          </NavButton>
          <NavButton direction="Next" off={rightDisabled} swiperObj={swiperRef}>
            <Icon icon={arrowRight} />
          </NavButton>
        </ButtonGroup>
      )}
      <Transition className="w-full" show>
        {list.length > 0 ? (
          scope === 'large' ? (
            slider === 'slider' ? (
              <Swiper
                className="h-full md:!px-2"
                spaceBetween={20}
                simulateTouch={false}
                ref={swiperRef}
                breakpoints={breakpoints}
                // eslint-disable-next-line no-sequences
                onFromEdge={() => (disableRight(false), disableLeft(false))}
                onReachBeginning={() => disableLeft(true)}
                onReachEnd={() => disableRight(true)}
              >
                {allItems?.map(item => (
                  <SwiperSlide key={item.id}>
                    <NewsItem item={item} slider={slider} />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className={newsHolderClasses}>
                {list?.map(item => (
                  <Transition.Child key={item.id} {...animationProps}>
                    <NewsItem item={item} />
                  </Transition.Child>
                ))}
              </div>
            )
          ) : (
            <NewsHolder>
              {list.slice(0, 3)?.map(item => (
                <NewsItem item={item} key={item.id} slider={slider} />
              ))}
            </NewsHolder>
          )
        ) : (
          <>{t('Inget att visa')}</>
        )}
      </Transition>

      {scope === 'large' && slider !== 'slider' && (
        <Footer>
          {hasMore ? (
            <>
              <Showing>
                {t('Visar')} 1-{list.length} {t('av')} {allItems.length}
              </Showing>
              <LoadMore type="button" onClick={handleLoadMore}>
                {t('Ladda fler')}
              </LoadMore>
            </>
          ) : (
            <Showing>({t('Visar alla')})</Showing>
          )}
        </Footer>
      )}
    </>
  )
}

const News = ({ data }) => {
  const { title, scope, sideLink, backgroundImage, slider } = data
  const posts = useStaticQuery(graphql`
    {
      sv: allWpPost(sort: { order: DESC, fields: [date] }, filter: { language: { code: { eq: SV } } }) {
        nodes {
          ...PostPreviewContent
        }
      }
      en: allWpPost(sort: { order: DESC, fields: [date] }, filter: { language: { code: { eq: EN } } }) {
        nodes {
          ...PostPreviewContent
        }
      }
    }
  `)

  const image = getImage(backgroundImage?.image)

  const backgroundFluidImageStack = [`linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))`, image]

  const { en, sv } = posts

  const { language } = useI18next()

  const items = language === 'en' ? en.nodes : sv.nodes

  const Container = styled.div`
    ${tw`transition-all duration-300`}
  `
  const ItemTitle = styled.h2`
    ${tw`px-10 md:px-2 text-4xl md:text-5xl font-light `}
    ${image ? tw`text-white mb-8 mt-2 ` : tw`text-ricoBlack mb-10`};
  `
  const Wrapper = styled.section`
    ${tw`max-w-7xl mx-auto py-16 flex flex-col md:flex-row md:flex-wrap content-between text-white justify-between md:px-8`}
  `
  const SideLink = styled(props => <Link {...props} />)`
    ${tw`order-last md:order-none font-bold text-primary border-b border-primary self-start pl-6 ml-8 mb-1 no-underline inline-block mt-5 md:mr-3`}
  `

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {items.length > 0 && (
        <Container className="mx-auto">
          <BgImage image={backgroundFluidImageStack}>
            <Wrapper className="transition-all duration-300">
              {title && <ItemTitle dangerouslySetInnerHTML={{ __html: sH(title) }} />}
              {sideLink && <SideLink to={sideLink.url} dangerouslySetInnerHTML={{ __html: sH(sideLink.title) }} />}
              <Newslisting items={items} scope={scope} slider={slider} />
            </Wrapper>
          </BgImage>
        </Container>
      )}
    </>
  )
}

News.propTypes = {}

News.defaultProps = {}

export default News

export const newsData = graphql`
  fragment News on WpPage_Pagebuilder_Layouts_News {
    fieldGroupName
    title
    scope
    slider
    sideLink {
      target
      url
      title
    }
  }
`
